<template>
  <div class="loans page">
    <section class="insurance-section" ref="#company-insurances">
      <MSectionHeader class="header" title="Credite" />
      <div class="companyAdvantages">
        <div class="blurOverlay mobile-only" />
        <div
          class="companyPerks__container companyAdvantages__section indexFix"
        >
          <div class="companyPerks__title">
            <h4>{{ $t("Ce Oferim") }}</h4>
          </div>
          <div class="companyPerks">
            <MPerk
              v-for="(perk, i) in offers"
              :key="i"
              :perk="perk"
              class="companyPerks__perk"
            />
          </div>
        </div>
        <div class="companyAdvantages__cover companyAdvantages__section">
          <img src="@/assets/images/offer_sm.jpg" />
        </div>
      </div>
      <div class="companyAdvantages">
        <div class="blurOverlay mobile-only" />
        <div class="companyAdvantages__cover companyAdvantages__section">
          <img src="@/assets/images/walk_sm.jpg" />
        </div>
        <div
          class="companyPerks__container companyAdvantages__section indexFix"
        >
          <div class="companyPerks__title">
            <h4>{{ $t("Top Avantaje") }}</h4>
          </div>
          <div class="companyPerks">
            <MPerk
              v-for="(perk, i) in advantages"
              :key="i"
              :perk="perk"
              class="companyPerks__perk"
            />
          </div>
        </div>
      </div>
    </section>
    <section class="insurance-section" ref="#how-it-works">
      <MSectionHeader class="header" title="Cum Funcționează" />
      <MSteps :steps="steps" class="steps" />
    </section>
    <section class="insurance-section" ref="faq">
      <MSectionHeader class="header" title="De ce Noi?" />
      <OServices
        :services="services"
        title="Întrebări Frecvente"
        :link="false"
      />
    </section>
  </div>
</template>
<script>
import MPerk from "@/components/molecules/m-perk";
import MSectionHeader from "@/components/molecules/m-section-header";
import MSteps from "@/components/molecules/m-steps";
import OServices from "@/components/organisms/o-services";

export default {
  components: {
    MPerk,
    MSectionHeader,
    MSteps,
    OServices,
  },
  data() {
    return {
      steps: [
        {
          title: "1. Documente necesare",
          list: [
            "Documentul de identitate valabil",
            "Ultimii 3 fluturași de salariu",
            "Extras de cont",
            "Dovada plăților la toți creditorii",
            "Alte informații relevante",
          ],
          image: require("@/assets/icons/consultation.png"),
        },
        {
          title: "2. Pregătirea dosarului",
          description:
            "Consultantul recomandă soluții de creditare și te ajută cu dosarul. Cerințe: 12 luni de angajare, maxim două schimbări de chirii în ultimele 24 de luni în Germania.",
          image: require("@/assets/icons/task.png"),
        },
        {
          title: "3. Aprobarea și semnarea",
          description:
            "După aprobare, semnezi documentele și banii sunt imediat în contul tău sau cum dorești!",
          image: require("@/assets/icons/deal.png"),
        },
      ],
      offers: [
        {
          icon: require("@/assets/icons/loan.png"),
          description: "Mobilare și renovare locuințe",
          // note: "From 1.49% p.a.",
        },
        {
          icon: require("@/assets/icons/refinancing.png"),
          description: "Asigurare pentru vacanțe",
          // note: "From 1.49% p.a.",
        },
        {
          icon: require("@/assets/icons/car-loan.png"),
          description: "Acoperire pentru investiții și datorii",
          // note: "From 1.49% p.a.",
        },
        {
          icon: require("@/assets/icons/vacation.png"),
          description: "Asigurare auto",
          // note: "From 1.49% p.a.",
        },
        {
          icon: require("@/assets/icons/sofa.png"),
          description: "Asigurare pentru achiziționarea imobilelor",
          // note: "From 1.49% p.a.",
        },
      ],
      advantages: [
        {
          icon: require("@/assets/icons/interest-rate.png"),
          description: "Împrumuturi de până la 100.000€",
        },
        {
          icon: require("@/assets/icons/calendar.png"),
          description: "Rambursare pe o perioadă de până la 120 de luni",
        },
        {
          icon: require("@/assets/icons/loan_2.png"),
          description: "Rambursare anticipată fără comision",
        },
        {
          icon: require("@/assets/icons/process.png"),
          description: "Fără comisioane ascunse",
        },
        {
          icon: require("@/assets/icons/money-back.png"),
          description: "Transparență totală și fără costuri ilegale",
        },
        {
          icon: require("@/assets/icons/shield-2.png"),
          description: "Acordare fără costuri",
        },
        {
          icon: require("@/assets/icons/shield-2.png"),
          description: "Consultanță și suport financiar gratuit",
        },
        {
          icon: require("@/assets/icons/shield-2.png"),
          description: "Analiză și aprobare flexibilă",
        },
      ],
      services: [
        {
          name:
            "Cum mă ajută consultanții să înțeleg serviciile și să răspund la întrebările mele?",
          content: {
            title: "Pentru că noi:",
            list: [
              "Detaliem fiecare pachet de servicii pentru o înțelegere completă.",
              "Răspundem la orice întrebare sau nelămurire aveți.",
              "Oferim consultanță personalizată pentru nevoile dumneavoastră financiare specifice.",
              "Facem recomandări și sugestii în funcție de situația dumneavoastră financiară individuală.",
              "Vă ghidăm pas cu pas prin procesul de luare a deciziilor, oferindu-vă încredere și claritate în alegerile financiare.",
            ],
          },
        },
        {
          name:
            "Ce avantaje aduce obținerea unui credit gratuit fără comisioane de intermediere ascunse?",
          content: {
            title: "Avantajele creditului gratuit fără comisioane ascunse",
            list: [
              "Accesibilitate: Oferta noastră permite accesul la un credit fără costuri ascunse, fără a fi necesară plata unor comisioane de intermediere.",
              "Economisire: Prin eliminarea comisioanelor de intermediere, puteți economisi sume semnificative pe termen lung.",
              "Transparență: Nu există surprize neplăcute legate de comisioane ascunse sau taxe suplimentare, oferindu-vă transparență și încredere în procesul de creditare.",
              "Flexibilitate: Beneficiați de un credit gratuit cu condiții flexibile și transparente, adaptate nevoilor dumneavoastră financiare.",
              "Confort: Procesul simplificat de obținere a creditului vă oferă confort și ușurință în gestionarea necesităților financiare.",
            ],
          },
        },
        {
          name:
            "Cum mă asigur că primesc îndrumare corectă și costuri minime pentru credit?",
          content: {
            title:
              "Garanții pentru îndrumare corectă și costuri minime pentru credit",
            list: [
              "Consultanță de încredere: Echipa noastră de experți vă asigură îndrumare corectă și transparentă în procesul de creditare.",
              "Politici transparente: Avem politici clare și transparente pentru a vă oferi cele mai mici costuri posibile pentru credit.",
              "Evaluare obiectivă: Asigurăm o evaluare obiectivă a situației dumneavoastră financiare pentru a vă oferi cele mai bune soluții.",
              "Comunicare deschisă: Suntem mereu disponibili pentru a răspunde întrebărilor și nelămuririlor dumneavoastră legate de costuri și procesul de creditare.",
              "Monitorizare continuă: Ne angajăm să monitorizăm costurile pe întreaga durată a creditului pentru a vă asigura că rămân la niveluri minime.",
            ],
          },
        },
      ],
    };
  },
  mounted() {
    window.scrollTo({ top: 0 });
  },
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.loans {
  padding: 96px var(--spacer-xl);
  margin: 0 auto;
  max-width: 1272px;
  @include for-mobile {
    padding: 84px var(--spacer-sm);
  }
}
.header {
  &::v-deep .title {
    text-align: center;
  }
  // 	&::v-deep .a-divider {
  // 		margin: var(--spacer-xl) 0;
  // 	}
  // 	&::v-deep .description {
  // 		margin: 0;
  // 	}
}
.companyAdvantages {
  display: flex;
  justify-content: space-between;
  margin: var(--spacer-2xl) 0;
  @include for-mobile {
    position: relative;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__section {
    &:nth-child(2) {
      @include for-desktop {
        margin-right: var(--spacer-2xl);
      }
    }
    @include for-mobile {
      padding: var(--spacer-sm) !important;
    }
  }
  &__cover {
    position: relative;
    width: 10rem;
    flex: 1 1 60%;
    border-radius: 25px;
    overflow: hidden;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include for-mobile {
        bottom: 0;
        height: 100%;
      }
    }
    @include for-mobile {
      position: absolute;
      width: calc(100% - 2rem);
      flex: 1 1 100%;
      top: 0;
      height: calc(100% - 2rem);
      border-radius: 15px;
    }
  }
}
.companyPerks {
  display: flex;
  flex-wrap: wrap;
  &__container {
    flex: 1 1 40%;
    padding: var(--spacer-sm) 0;
  }
  &__title {
    h4 {
      margin: 0 0 var(--spacer-sm);
      font-family: var(--font-family-secondary);
      font-weight: var(--font-bold);
      font-size: 1.5rem;
      border-bottom: 2px solid var(--c-primary);
      padding: 0 0 var(--spacer-sm);
    }
  }
  &__perk {
    flex: 1 1 calc(50% - 2rem);
    max-width: calc(50% - 2rem);
    padding: var(--spacer-lg) var(--spacer-sm);
  }
  &__sidenote {
    display: block;
    font-family: var(--font-family-secondary);
    font-size: var(--font-xs);
    color: var(--c-gray-lighten);
    padding: var(--spacer-sm);
    text-align: center;
  }
}
.companyInsurances {
  display: flex;
  flex-wrap: wrap;

  &__card {
    margin: var(--spacer-sm);
    flex: 1 1 calc(25% - 2rem - 2px);
    min-height: 10rem;
    // max-width: calc(25% - 2rem - 2px);
    height: 34rem;
  }
}
.steps {
  &::v-deep .perk__content {
    max-width: 14rem;
  }
}
.insurance-section {
  padding: var(--spacer-2xl) 0;
  @include for-mobile {
    padding: var(--spacer-xl) 0;
  }
}
.indexFix {
  @include for-mobile {
    z-index: 2;
  }
}
.blurOverlay {
  z-index: 1;
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(2px);
  width: 100%;
  height: 100%;
  border-radius: 15px;
}
</style>
